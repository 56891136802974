.info {
  font-size: .875rem;
  font-weight: 400;
  list-style: none;
  
  li {
    position: relative;
    padding-left: 1.25rem;
    padding-bottom: .25rem;
    
    .checkmark {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      font-size: .875rem;
      font-weight: 400;
      transform: translateY(18%);
    }
  }

  &.bulleted {
    li {
      padding-left: .75rem;

      .checkmark {
        display: none;
      }
      &:before {
        content: '\2022';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 1.1rem;
        font-weight: 400;
        transform: translateY(-12%);
        }
    }

  }
}
